.side-panel {
    width: 300px !important;

    .search-container {
        padding: 0 16px;

        .input-field,
        .text-input-field {
            margin-top: 13px;

            p {
                color: rgba(255, 0, 0, 0.8);
            }
        }

        .text-input-field {
            padding: 0;
            margin-right: -0.5rem;

            .submit-button {
                border-radius: 0 4px 4px 0;
            }
        }

        .search-list-container {
            margin-top: 40px;

            .list-item {
                padding: 11px 19px 11px 11px;
                cursor: pointer;
                display: flex;
                justify-content: space-between;

                .search-actions {
                    opacity: 0;
                    transition: 0.3s;
                }

                &:hover .search-actions {
                    opacity: 1;
                }
            }

            .list-item-selected {
                background-color: #ff990066;
            }
        }
    }
}

.default-select-value {
    min-height: 36px !important;
}