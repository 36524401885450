@import "../../../styles/mixins.scss";

.contextual-menu-item-container {
    gap: rem(10);
    padding: rem(10) !important;
    font-size: rem(15) !important;
    line-height: normal !important;
    font-weight: 400 !important;
    text-transform: capitalize;
    color: #666 !important;
    border-radius: rem(4) !important;

    svg {
        height: rem(16);
        width: auto;
        color: inherit;
    }

    &:hover {
        background-color: rgb(0, 42, 76, .1);
    }

    &.variant-red {
        color: #E97590 !important;

        &:hover {
            background-color: rgb(233, 117, 144, .15);
        }
    }
}