@import "../../../../styles/mixins.scss";

.menu-container {
    position: relative;
    border-radius: rem(3) !important;
    border: 1px solid rgba(0, 42, 76, 0.2);
    box-shadow: 0px 4px 16px -8px rgba(0, 0, 0, 0.16) !important;
    max-width: fit-content;

    .menu-search-field {
        padding: rem(8) rem(16);
        line-height: 1;
        border-bottom: 1px solid rgba(0, 42, 76, 0.2);
        margin-bottom: rem(8);

        & > div {
            width: 100%;

            & > div {
                padding: 0;
                font-size: rem(13);
                height: auto;
            }

            svg {
                width: rem(21);
            }

            fieldset {
                border: 0;
            }

            input {
                padding: 0;
            }
        }
    }

    ul {
        max-height: rem(525) !important;
        max-width: rem(350) !important;
        padding: 0;

        .menu-item {
            display: flex;
            align-items: center;
            padding: rem(4) rem(16);
            width: 100%;
            gap: rem(8);

            &.menu-item-selected {
                background-color: unset;
            }

            &.iconRight {
                flex-direction: row-reverse;
                justify-content: space-between;
            }

            img,
            .menu-item-icon {
                position: relative;
                flex-shrink: 0;
                width: rem(18);
                height: rem(18);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: rem(10);
                font-weight: 500;
                color: #fff;
                line-height: 1;
            }

            .menu-item-icon::after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 100%);
                border-radius: 50%;
            }

            .menu-item-value {
                display: flex;
                align-items: center;
                width: calc(100% - 18px - 8px - 8px);
                gap: rem(8);
                flex-grow: 1;

                .menu-item-value-title {
                    font-size: rem(14);
                    line-height: rem(24);
                    font-weight: 400;
                    text-transform: capitalize;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1 1 50%;
                }

                .menu-item-value-description {
                    font-size: rem(13);
                    color: rgba(0, 42, 76, 0.4);
                    text-transform: lowercase;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 0 1 50%;
                }
            }

            &:hover {
                background-color: rgb(216, 216, 216, .4);
            }
        }
    }
}