@import "mixins.scss";

.actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.scrollBar {
    overflow-x: hidden;
    overscroll-behavior: none;

    .list {
        @include flex-grid(12, 0);
        padding-bottom: 0;
    }
}

.paper {
    min-height: 0;
    height: 100%;
    margin-top: 8px;
}

@media only screen and (max-wdith: 1150px) {
    .paper {
        padding-bottom: 0;
        height: 350px;
    }
}

.teamMemberList {
    display: flex;
    flex-direction: column;
    height: 100%;
}