.open,
.normal,
.planned {
    background-color: #E6F6CF !important;

    span,
    p {
        color: #4DA400;
    }
}

.active,
.started {
    background-color: #E0F1FB !important;

    span,
    p {
        color: #3D85C6;
    }
}

.in_progress,
.archived {
    background-color: #A6E0FC !important;

    span,
    p {
        color: #444;
    }
}

.resolved,
.complete {
    background-color: #0070E4 !important;

    span,
    p {
        color: #FFF;
    }
}

.unset,
.closed,
.on_hold {
    background-color: rgba(204, 204, 204, 0.6) !important;

    span,
    p {
        color: rgba(102, 102, 102, 0.6);
    }
}

.due,
.bug,
.show_stopper,
.superseded {
    background-color: #E30000 !important;

    span,
    p {
        color: #FFF;
    }
}

.wish,
.critical {
    background-color: #FFC8EA !important;

    span,
    p {
        color: #444;
    }
}

.question,
.major {
    background-color: #FFEE9C !important;

    span,
    p {
        color: #B45F06;
    }
}

.task {
    background-color: #7DBD36 !important;

    span,
    p {
        color: #FFF;
    }
}

.minor {
    background-color: #E6E6E6 !important;

    span,
    p {
        color: #444;
    }
}