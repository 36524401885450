@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.formTitleHeading {
    font-weight: 900;
    font-size: rem(18);
    padding: 0;
    margin: rem(10) rem(10) rem(10) rem(8);
}

.formTitle {
    font-weight: 500;
    font-size: rem(16);
    padding: 0;
    margin: 0;
}

.formText {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.39);
    padding: 0;
    margin: 0;
}

.pencil {
    color: rgb(87, 87, 87);
    visibility: hidden;
}

.editlabel {
    position: relative;
    width: max-content;
}

.editlabel:hover > .pencil {
    visibility: visible;
}

.actions-container {
    display: flex;
}

.copy-link-container {
    display: flex;
    align-items: center;
    width: 100%;
    height: rem(38);
    border-radius: 4px;
    border: 1px dashed rgb(224, 224, 224);
    background-color: #F9F9F9;

    .copy-link-input {
        flex-grow: 1;

        input {
            color: #B7B7B7;
            font-weight: 400;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .copy-link-private-settings {
        margin: 0 rem(6);
    }
}

.public-project-settings-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    border-bottom: 1px solid #E0E0E0;

    svg {
        margin: 0 rem(12) rem(18) rem(12);
        height: rem(30);
        width: auto;
    }
}

.public-project-settings-values {
    margin-top: rem(18);

    svg {
        margin: 0 rem(12);
        cursor: pointer;
        height: rem(24);
        width: auto;
        padding: 0 rem(3);
    }
}

.project-settings-heading-container {
    padding-top: rem(4);
    padding-bottom: rem(13);

    .project-settings-heading {
        color: #595959;
    }

    svg {
        cursor: help;
        height: rem(16);
        width: auto;
        margin-left: rem(5);
    }
}

.project-info-container {
    @include flex-grid(12, 15);

    .project-info-title {
        font-weight: 400;
    }

    & > div {
        @include flex-grid-col(12, 12, 15);
        margin-bottom: rem(30);

        p:nth-of-type(2) {
            font-weight: 400;
        }
    }
}

.manage-tab-container {
    .delete-project-title {
        color: $red;
    }

    .delete-project-help {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(16);
        color: $text-alternate;
    }

    .delete-project-button {
        padding: 0 rem(16);
        font-size: rem(12);
        line-height: rem(24);
        border-color: $blue;
        text-transform: initial;
        box-shadow: 0px 2px 5px rgba(0, 42, 76, 0.15);
        flex-grow: 1;
        flex-shrink: 0;

        &.delete-button {
            color: $white;
            background-color: $red-light;
            border-color: $red;
            margin-top: rem(10);

            &:hover {
                border-color: $red-light;
                background-color: $red;
            }
        }
    }
}

.panelTitle {
    font-size: rem(16) !important;
    font-weight: 300 !important;
    line-height: rem(19) !important;
    text-transform: uppercase;
    color: #575757;
}

.project-member-container {
    display: flex;
    gap: rem(15);

    .project-member-action {

        img,
        .member-icon {
            position: relative;
            flex-shrink: 0;
            width: rem(24);
            height: rem(24);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-size: rem(14);
            font-weight: 500;
            color: #fff;
            margin: rem(8);
        }

        .member-icon::after {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(135deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .1) 100%);
            border-radius: 50%;
        }

        .project-member-action-delete {
            display: none;

            svg {
                width: rem(24);
                height: auto;
                fill: #E97590;
            }
        }
    }

    .project-member-name {
        font-size: rem(16);
        line-height: rem(24);
        font-weight: 400;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1 40%;
    }

    .project-member-description {
        font-size: rem(14);
        color: rgba(0, 42, 76, 0.4);
        text-transform: lowercase;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 1 40%;
        white-space: nowrap;
    }

    .project-member-roles {
        flex: 1 1 20%;

        div[role=button] {
            padding-top: 0;
            padding-bottom: 0;
            color: rgba(0, 42, 76, 0.6);

            &:hover {
                color: #f6a81c;
            }
        }

        fieldset {
            border: none;
        }
    }

    &:hover {

        img,
        .member-icon {
            display: none;
        }

        .project-member-action-delete {
            display: flex;
        }
    }
}

@media (min-width:768px) {
    .project-info-container {
        & > div {
            @include flex-grid-col(6, 12, 15);
        }
    }
}