.clip {
    display: flex;
    background-color: white;
    width: 250px;
    height: 70px;
    margin-top: -1px;
    clip-path: polygon(0 1%, 100% 0, 82% 100%, 0% 100%);

    a {
        display: flex;
        height: 100%;
        width: 100%;

        img {
            margin-left: 20px;
            margin-top: auto;
            margin-bottom: auto;
            width: 175px;
            height: 75%;
        }
    }

}

.userFunctionality {
    display: flex;
    gap: 8px;

    .divider {
        transform: rotate(0);
        font-size: large;
        width: 2px;
        border-radius: 5%;
    }

    > div {
        margin: auto 0;
    }
}

@media (max-width: 600px) {
    .clip {
        clip-path: polygon(0 1%, 100% 0, 70% 100%, 0% 100%);
    }

    .show {
        display: none;
    }
}

@media (min-width: 601px) {
    .hide {
        visibility: collapse;
    }
}