.title {
    margin-left: auto;
    margin-right: auto;
    flex-grow: 4;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: 8px;
}

.memberInfo {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.memberInfoContent {
    padding-bottom: 0px;
    height: 100%;
}

.memberInfoPaper {
    height: 100%;
    position: relative;
    padding-top: 24px;
    margin-top: 8px;
}

@media only screen and (max-width: 1150px) {
    .memberInfoContent {
        height: 600px;
    }

    .memberInfoPaper {
        height: 600px
    }
}

.memberInfoText {
    width: auto;
    font-size: clamp(1rem, -0.875rem + 4vw, 1.1rem);
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.memberPermissions {
    padding-left: 10px;
    width: auto;
    font-size: clamp(1rem, -0.875rem + 4vw, 1.1rem) !important;
    color: rgba(0, 0, 0, 0.6);
}

.memberInfoRole {
    width: auto;
    font-size: clamp(1rem, -0.875rem + 4vw, 1.1rem) !important;
    color: rgba(0, 0, 0, 0.6) !;
    font-weight: 300;
}

.permissionBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userInfo {
    padding: 20px 30px 0 30px;
}

.memberInfoAvatar {
    width: 14rem !important;
    height: 14rem !important;
    font-size: 7rem !important;
}

.inactiveIcon {
    font-size: 200% !important;
}

.inactiveBox {
    margin: 8px;
    text-align: center;
}

.memberRole {
    padding-top: 8px;
    width: 100%;
}

.invalidMemberRole {
    color: rgba(255, 0, 0, 0.541) !important;
    font-style: italic;
}

.roleSelect {
    width: 100% !important;
}

.roleForm {
    width: 100%;
}

.removeButton {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    margin-bottom: 16px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    transform: translateX(-50%);
}