@media (max-width: 1150px) {
    .teamControllerBox {
        display: flex;
        flex-direction: column;
        gap: 10px 30px;
        padding: 10px 30px;
    }
}

@media (min-width: 1150px) {
    .teamControllerBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px 30px;
        padding-left: 12px;
        height: calc(100vh - 90px);
    }

    .admin {
        grid-template-rows: 50%;
        grid-template-areas: " teams members info"
            "roles roles info";
    }

    .noAdmin {
        grid-template-rows: 100%;
        grid-template-areas: " teams members info";
    }
}