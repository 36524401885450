@import "../styles/mixins.scss";

.closeButton {
    float: right;
    margin-left: auto !important;
}

.endSelect {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;

    div[role=button] {
        width: rem(94);
    }
}

.endSelect > fieldset {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.scrollBox {
    max-height: rem(400);
    overflow-y: scroll;
    margin-right: rem(-15);
    padding-right: rem(15);
}