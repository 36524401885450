.loginDialog {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    transition: width 0.5s, height 0.5s;

    .titleBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        position: relative;
        gap: 16px;

        .closeButton {
            position: absolute !important;
            top: 0;
            right: 0;
        }

        .backButton {
            position: absolute !important;
            top: 0;
            left: 0;
        }
    }

    .dialogContent {
        width: min(280px, 100vw);
        margin-left: auto;
        margin-right: auto;

        .container {
            display: flex;
            flex-direction: column;
            gap: 16px;


            .goToLoginContainer {
                display: inline-flex;

                .loginButton {
                    padding: inherit;

                }
            }
        }

    }


    .footer {
        margin-top: 24px;
        text-align: center;
    }
}