@import "styles/mixins.scss";
@import "styles/colors.module.scss";

.file-input {
    border: 1px dashed rgba(0, 0, 0, 0.23) !important;
    height: 164px;
    background-color: #f0f0f0 !important;
    border-radius: 3px !important;
    transition: border 0.3s;
    box-shadow: none !important;
    margin-bottom: 10px;

    p {
        color: rgba(0, 0, 0, 0.54);
    }

    svg {
        color: rgba(0, 0, 0, 0.54);
        font-size: 40px !important;
        margin-top: 20px;
    }

    &:hover {
        border: 1px dashed rgba(0, 0, 0, 0.87) !important;
    }
}

.file-input-disabled {
    @extend .file-input;
    border: 1px dashed rgba(0, 0, 0, 0.26) !important;
    background-color: #f0f0f0 !important;

    p {
        color: #b4b4b4 !important;
    }

    svg {
        color: #b4b4b4;
    }

    &:hover {
        border: 1px dashed rgba(0, 0, 0, 0.26) !important;
    }
}

.modelActions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 6px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    button {
        width: 100%;

        &:nth-of-type(n + 2) {
            margin-left: 8px;
        }
    }
}

.automation-service {
    display: flex;
    align-items: flex-end;
    line-height: 1;
}

.model-list-container {
    margin: 6px;
}

.model-container {
    position: relative;
    background: #FFF;
    border: 1px solid rgba(71, 81, 89, 0.2);
    border-radius: 2px !important;
    box-shadow: none !important;
    padding: 5px;

    &.model-expanded {
        .model-summary {
            .model-summary-content {
                .model-summary-closed {

                    &:not(.model-with-no-revisions)::after {
                        position: absolute;
                        content: '';
                        left: -16px;
                        width: 1px;
                        height: 100%;
                        top: 20px;
                        background-color: #E0DFDA;
                    }

                    .model-summary-description {
                        opacity: 1;
                        max-height: 100%;
                        margin: 2px 0 8px 0;
                    }
                }
            }
        }
    }

    .model-with-no-revisions {
        > .model-summary-content {
            cursor: default;
        }
    }

    .model-summary {
        padding: 0;

        .model-summary-content {
            margin: 0;

            img {
                object-fit: cover;
                z-index: 1;
                border-radius: 2px;
                background-color: rgb(224, 223, 218);
            }

            .model-summary-closed {
                margin-left: 10px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                position: relative;

                .model-summary-title {
                    font-size: 21px;
                    font-weight: 500;
                    line-height: normal;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .model-summary-subtitle {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(87, 87, 87, 0.6);
                    line-height: normal;
                }

                .model-summary-description {
                    opacity: 0;
                    font-size: 13px;
                    max-height: 0;
                    font-weight: 400;
                    line-height: 18px;
                    margin: 0;
                    overflow: hidden;
                    transition: opacity .3s;
                }
            }
        }
    }

    .model-actions {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        transition: opacity .3s;
        display: flex;
        gap: 2px;

        button {
            padding: 3px;
            cursor: pointer;
        }

        svg {
            width: auto;
            height: 16px;
        }
    }

    .model-details-container,
    .model-summary {
        &:hover {
            .model-actions {
                opacity: 1;
            }
        }
    }

    .model-summary-info {
        display: flex;

        .model-summary-info-item {
            display: flex;
            gap: 3px;
            align-items: center;
            white-space: nowrap;

            &:not(:last-of-type) {
                margin-right: 8px;
            }

            .model-summary-status-dot {
                width: 7px;
                height: 7px;
                background-color: rgba(204, 204, 204, 0.6);
                border-radius: 50%;
                border: 1px solid rgba(130, 130, 130, 0.1);
            }

            svg {
                height: 10px;
                width: auto;
                color: #929292;
            }

            .model-summary-info-item-title {
                font-size: 12px;
                color: #B0B0B0;
                font-weight: 400;
                line-height: normal;
                text-transform: capitalize;
                transition: color .3s;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .model-summary-revision-amount {
                background-color: #f6a81c;
                border-radius: 5px;
                font-size: 10px;
                color: #fff;
                line-height: 1;
                padding: 2px 5px;
            }

            .state-selector {
                display: flex !important;

                div {
                    padding: 0;
                    margin: 0;
                    min-height: auto;
                    height: auto;
                    width: 100%;
                    border-radius: 0;

                    p {
                        display: none;
                    }
                }

                div[role=button] {
                    font-size: 12px;
                    color: #B0B0B0;
                    font-weight: 400;
                    border-bottom: 1px solid transparent;
                    transition: all .1s;
                    text-transform: capitalize;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    line-height: 1;
                }

                svg {
                    display: none;
                }

                fieldset {
                    border: none;
                }

                div:first-of-type:hover {
                    div[role=button] {
                        color: #F9AF08;
                    }
                }
            }
        }
    }

    .model-details-container {
        position: relative;
        margin: 15px 0 0 75px;
        padding: 0 !important;

        &:not(:last-of-type)::after {
            position: absolute;
            content: '';
            left: -16px;
            width: 1px;
            height: calc(100% + 7px);
            top: 13px;
            background-color: #E0DFDA;
        }

        &:first-of-type {
            padding-top: 20px;
        }

        .revision-summary-title {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            color: #575757;

            &::before {
                content: '';
                position: absolute;
                left: -20px;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                border: 1px solid #E0DFDA;
                top: calc(50% - 4px);
                background-color: #FFF;
            }
        }

        .revision-summary-description {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin: 3px 0;
        }
    }
}

.menu-container {
    border-radius: 3px !important;
    border: 1px solid rgba(246, 170, 28, 0.1);
    box-shadow: 0 2px 16px 0 rgba(0, 42, 76, .15) !important;

    .menu-list-container {
        padding: 8px 0;

        .menu-item-container {
            padding: 5px 16px;
            font-size: 13px;
            text-transform: capitalize;
            justify-content: space-between;
            gap: 20px;

            .menu-item-indicator {
                width: 15px;
                height: 15px;
                border-radius: 50%;
                border: 1px solid rgba(130, 130, 130, 0.1);
            }
        }
    }
}

.dialog-content-container {
    position: relative;

    &.dialog-content-container-dragged {
        &::before {
            content: 'Drop files here';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            border: 2px dashed $primary;
            background-color: rgba($color: #fff, $alpha: .8);
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: rem(30);
            color: $primary;
        }
    }
}