.grid {
    height: 100vh;
    padding: 50px 7vw 35px 7vw;
    min-height: 100vh;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(100% - 1px);
        width: 18.201%;
        height: 100%;
        clip-path: polygon(0 0, 100% 0, 0 100%, 0% 100%);
        background-color: white;
    }
}

.section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.divider {
    margin-top: 12px;
}

.corners {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.account {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.forgot {
    padding-top: 10px;
    padding-bottom: 10px;
}

@media (max-width: 300px) {
    .divider {
        font-size: 0.70rem;
    }
}

@media (max-width: 900px) {
    .grid {
        padding: 50px 5vw 35px 5vw;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .grid {
        padding: 50px 5vw 35px 5vw;
    }
}