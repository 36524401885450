@import 'styles/mixins.scss';

.folder-icon path {
    //TODO: figure out how to do gradients on svgs
    // fill: linear-gradient(90deg, rgba(66, 165, 245, 0.5) 0%, rgba(66, 165, 245, 0.35) 100%);
    fill: rgba(66, 165, 245, 0.35) !important;
}

.folder-linked-container {
    display: flex;
    align-items: center;
    gap: rem(5);

    .folder-linked-wrapper {
        display: flex;
        align-items: center;
        gap: rem(5);

        .linked-icon {
            font-size: rem(13);
            fill: rgba(249, 175, 8, 0.5);
            transform: rotate(-30deg);
        }

        .linked-entity-name {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(14);
            color: #B0B0C1;
        }
    }
}

.arrow-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}