//grid system
@mixin flex-grid($columns: $grid-columns, $gap: $grid-gap-hor) {
    display: flex;
    flex-flow: row wrap;
    position: relative;
    min-height: 1px;
    margin-left: rem(calc(-#{$gap} / 2));
    margin-right: rem(calc(-#{$gap} / 2));
}

@mixin flex-grid-col($number, $columns: $grid-columns, $gap: $grid-gap-hor) {
    position: relative;
    margin-left: rem(calc(#{$gap} / 2));
    margin-right: rem(calc(#{$gap} / 2));
    min-height: 1px;

    @if ($number=='auto') {
        width: 100%;
        max-width: none;
        flex: 1 1 auto;
        flex-basis: auto;
    }

    @else {
        width: calc(100% * #{$number} / #{$columns} - #{rem($gap)});
        flex: 0 0 calc(100% * #{$number} / #{$columns} - #{rem($gap)});
        flex-basis: calc(100% * #{$number} / #{$columns} - #{rem($gap)});
    }
}

@function rem($pxval) {
    @return calc(#{$pxval} / 16 * 1rem);
}