.clipi {
    background-size: cover;
    background-color: rgba(206, 158, 63, 0.4);
    background-image: url("../assets/requestAccessImage.jpg");
    background-blend-mode: screen;
    clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0 100%, 13% 0);
}

.grid {
    height: 100vh;
    padding: 50px 7vw 35px 7vw;
    min-height: 100vh;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: "center";
}

.content {
    flex: 1;
    margin-top: 10vh;
}

.account {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}