.button {
    justify-content: center !important;
    display: block !important;
    line-height: 0 !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: center !important;
    border: 0 !important;

    .buttonText {
        justify-content: center !important;
        margin: 0 !important;
        text-transform: none;
        display: block;
        text-align: center;
    }

    .buttonIndicator {
        background-color: #f6a81c;
        position: fixed;
    }

    &[disabled] {
        pointer-events: auto !important;

        .buttonText {
            color: inherit;
        }

        &:hover {
            background-color: initial;
        }
    }
}

.buttonSelected {
    background-color: transparent !important;
    box-sizing: content-box;

    svg {
        color: #f6a81c;
    }

    .buttonText {
        color: #f6a81c;
    }
}

.sidebarIcon {
    flex-direction: column;
    align-items: center;
}

.actionToggleGroup {
    display: inline !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.sidePanel {
    position: absolute !important;
    pointer-events: none !important;
}

.rightPanelPaper {
    pointer-events: all !important;
    position: inherit !important;
    width: 20vw !important;
    right: 100% !important;
    transform-origin: right !important;
}

.rightPanelModal {
    padding-right: 50px !important;
}

.leftPanelPaper {
    pointer-events: all !important;
    position: inherit !important;
    width: 20vw !important;
    left: 100% !important;
    transform-origin: left !important;
}

.leftPanelModal {
    padding-left: 50px !important;
}

.sidebar-container {
    position: relative;
    height: 100%;

    .button-container {
        padding-top: 8px;
        display: flex;
        flex-direction: column;

        .button {
            justify-content: center !important;
            display: block !important;
            line-height: 0 !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: center !important;
            border: 0 !important;

            .buttonText {
                justify-content: center !important;
                margin: 0 !important;
                text-transform: none;
                display: block;
                text-align: center;
            }

            .buttonIndicator {
                background-color: #f6a81c;
                position: fixed;
            }

            &[disabled] {
                .buttonText {
                    color: inherit;
                }
            }
        }

        .stacking-button {
            display: flex;
            flex-direction: column;
        }

        .button-tooltip {
            pointer-events: auto;
        }
    }
}

.hide {
    visibility: collapse;
}

@media screen and (min-height: 540px) {
    .hide {
        visibility: visible;
    }
}

.wrapper-container {
    height: 100%;
    margin-right: auto;
    margin-left: auto;
}