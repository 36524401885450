@import 'styles/mixins.scss';

.toolbar-container {
    background-color: white;
    position: absolute;
    bottom: rem(10);
    border-radius: 2px;
    left: 50%;
    transform: translateX(-50%);
    padding: rem(2.5);
    display: flex;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

    button {
        border: 0;
        border-radius: 2px !important;
        width: rem(60);
        height: rem(60);
        padding: rem(6);
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: auto;
        color: #575757;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }

        &:disabled {
            border: 0;
        }

        &:not(:first-of-type) {
            margin-left: rem(2.5) !important;
        }

        svg {
            color: #666;
        }

        .toolbar-button-label {
            font-size: rem(10);
            text-transform: capitalize;
            margin-top: rem(5);
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            line-height: 1;
        }

        &.extra-button {
            width: rem(60);
            transition: all .3s;
            overflow: hidden;

            &:before {
                content: '';
                width: rem(1);
                height: 100%;
                background-color: #d9d9d9;
                position: absolute;
                left: 0;
            }

            &:disabled {
                width: 0;
                padding: 0;
                margin-left: 0 !important;
                border-left: 0 !important;
            }
        }

        .multi-level {
            position: absolute;
            top: rem(1);
            width: rem(10);
            height: auto;
        }

        &.active {
            color: rgba(0, 0, 0, 0.54);
            background-color: rgba(249, 175, 8, .4);
        }
    }

    .grouped-button {
        position: relative;

        .actionContainer {
            position: absolute;
            bottom: calc(100% + rem(6));
            display: flex;
            flex-direction: column;
            left: 50%;
            transform: translateX(-50%);
            background-color: white;
            border-radius: 2px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
            display: none;

            &::before {
                content: '';
                width: rem(11);
                height: rem(11);
                background-color: #fff;
                transform: rotate(45deg) translateX(-50%);
                position: absolute;
                bottom: rem(-9);
                left: 50%;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: rem(-5.5);
                left: 0;
                right: 0;
                z-index: -1;
            }

            button {
                padding: rem(5) rem(10);
                justify-content: start;
                text-transform: none;
                border-radius: 0 !important;
                flex-direction: row;
                min-width: max-content;
                width: 100%;
                height: auto;
                margin-left: 0 !important;
                text-align: start;

                svg {
                    width: rem(18);
                    height: auto;
                    margin-right: rem(6);
                }

                img {
                    margin-right: rem(6);
                }

                &::before {
                    display: none;
                }

                &.extra-button {
                    border-bottom: 1px solid #d8d8d8 !important;
                }
            }
        }

        &:hover {
            .actionContainer {
                display: block;
            }
        }
    }
}

.contextual-menu-container {
    background-color: white;
    border-radius: 2px;
    padding: rem(2.5);
    display: flex;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    width: fit-content;
    position: absolute;
    transform: translate(-50%, calc(-100% - 8px));

    &::before {
        content: '';
        width: rem(11);
        height: rem(11);
        background-color: #fff;
        transform: rotate(45deg) translateX(-50%);
        position: absolute;
        bottom: rem(-9);
        left: 50%;
    }

    .inline-input-container {
        padding: 0;
    }

    .file-input-container {
        flex-direction: column-reverse;
    }

    .button,
    button {
        border: 0 !important;
        border-radius: 2px !important;
        width: rem(64);
        height: rem(60);
        padding: rem(6);
        display: flex;
        flex-direction: column;
        position: relative;
        color: #575757;

        &:not(:first-of-type) {
            margin-left: rem(2.5) !important;
        }

        svg {
            color: #666;
        }

        .toolbar-button-label,
        p {
            font-size: rem(11);
            text-transform: capitalize;
            margin-top: rem(5);
            font-weight: 400;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
            line-height: 1;
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.remove-measurements {
    width: rem(16);
    height: rem(16);
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(102, 102, 102, 0.3);

    svg {
        width: rem(14);
        height: rem(14);
        color: #666;
    }
}