.grid {
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginSidePanel {
    background-color: white;
    z-index: 1;
    position: relative;
}

.imageSidePanel {
    position: relative;
    height: 100%;
}

.clipi {
    position: absolute;
    height: 100vh;
    right: -100px;
    top: 0;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .75;
    }
}

@media (max-width: 900px) {
    .responsive {
        display: none;
    }
}