.searchField {
    transition: width ease-in-out(0.5s);
}

.actions {
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.teamList {
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: height 0.25s;
}

.paper {
    min-height: 0;
    padding-bottom: 0;
    height: 100%;
    transition: height 0.25s;
    margin-top: 8px;
}

@media only screen and (max-wdith: 1150px) {
    .paper {
        height: 350px;
    }
}