@import 'styles/mixins.scss';

.marked-for-cut p {
    color: rgba(216, 216, 216, 0.8) !important;
}

.image-icon path,
.pdf-icon path {
    //TODO: figure out how to do gradients on svgs
    // fill: linear-gradient(90deg, rgba(227, 0, 0, 0.5) 0%, rgba(227, 0, 0, 0.35) 100%);
    fill: rgba(227, 0, 0, 0.5) !important;
}

.file-icon path {
    //TODO: figure out how to do gradients on svgs
    // fill: linear-gradient(90deg, rgba(125, 189, 54, 0.5) 0%, rgba(125, 189, 54, 0.35) 100%);
    fill: rgba(125, 189, 54, 0.5);
}


.file-details-container {
    display: flex;
    align-items: center;
    gap: rem(5);

    .divider-dot {
        width: rem(3);
        height: rem(3);
        background: #B0B0C1;
        border-radius: rem(1.5);
    }
}