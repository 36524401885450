@import 'styles/mixins.scss';

.sidePanel {
    width: rem(385) !important;

    .no-content {
        margin: auto;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: rem(35);
        font-style: italic;
        color: rgba(0, 0, 0, 0.24);
    }

    .panel-content {
        padding: rem(10) !important;
        height: 100%;
    }
}

.back-folder-button {
    display: flex;
    gap: rem(15);
    padding: rem(8) 0;
    cursor: pointer;
    align-items: center;

    &:hover {

        p,
        svg path {
            color: #F9AF08;
            fill: #F9AF08;
        }
    }

    p,
    svg {
        color: #575757;
    }

    p {
        font-weight: 500;
        font-size: rem(15);
        line-height: rem(18);
        transition: .3s color;
    }

    svg {
        font-size: rem(20);

        path {
            transition: .3s fill;
        }
    }
}

.list {
    display: flex;
    flex-direction: column;
    gap: rem(10);
    padding: 0 !important;
    height: 100%;

    .document-container {
        border-radius: rem(8);
        background: rgba(0, 0, 0, 0.03);
        padding: rem(8);
        cursor: pointer;
        transition: .2s all;
        min-height: rem(47);
        display: flex;
        align-items: center;
        gap: rem(10);

        &:hover {
            background: rgba(0, 0, 0, 0.08);
        }

        .document-icon-wrapper {
            width: rem(26);
            height: rem(24);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: rem(8);

            svg {
                font-size: rem(19);
            }
        }

        .document-information {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
        }

        .document-name {
            font-weight: 500;
            font-size: rem(15);
            line-height: rem(18);
            color: #575757;
            transition: color .3s;
        }

        .folder-contents-amount,
        .file-info {
            font-weight: 400;
            font-size: rem(11);
            line-height: rem(13);
            color: #B0B0C1;
        }
    }
}