@import "mixins.scss";

.onhover .hiddenChild {
  opacity: 0;
}

.onhover:hover .hiddenChild {
  opacity: 1;
  transition: opacity 350ms ease;
}

.box {
  transition: box-shadow .3s;
  padding: 4px;
  padding: 10px;
  width: 100%;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, .2);
  border-radius: 8px;
}

.memberAvatar {
  width: clamp(3rem, -0.875rem + 8vw, 5rem) !important;
  height: clamp(3rem, -0.875rem + 8vw, 5rem) !important;
  font-size: clamp(1.5rem, -0.4315rem + 4vw, 2.5rem) !important;
  margin: 0 auto;
}

.inactiveIcon {
  font-size: 200% !important;
}

.memberButton {
  padding: 0;
  border-radius: 16px;
  margin-bottom: rem(8);
  @include flex-grid-col(12, 12, 8);
}

.memberInfo {
  width: 100%;
  text-align: center;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width:425px) {
  .memberButton {
    @include flex-grid-col(6, 12, 16);
  }
}

@media (min-width:768px) {
  .memberButton {
    @include flex-grid-col(4, 12, 16);
  }
}

@media (min-width:1024px) {
  .memberButton {
    @include flex-grid-col(3, 12, 16);
  }
}

@media (min-width:1150px) {
  .memberButton {
    @include flex-grid-col(6, 12, 16);
  }
}

@media (min-width:1600px) {
  .memberButton {
    @include flex-grid-col(4, 12, 16);
  }
}

@media (min-width:2204px) {
  .memberButton {
    @include flex-grid-col(3, 12, 16);
  }
}

@media (min-width:2732px) {
  .memberButton {
    @include flex-grid-col(2, 12, 16);
  }
}