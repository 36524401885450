@import "../styles/mixins.scss";

.contentLayoutStyle {
    display: grid;
    grid-template-columns: rem(75) 1fr;
    grid-template-areas: "header header" "sidebar main";
    gap: 0;
    width: 100vw;
    height: 100vh;
}

.component {
    margin: rem(8);
    grid-area: main;
    overflow: hidden;
}

@media only screen and (max-width: 1150px) {
    .component {
        overflow: scroll;
    }
}

.activity-list-container {
    @include flex-grid(12, 0);
    height: 100%;

    .activity-container {
        @include flex-grid-col(12, 12, 10);
        margin-top: rem(10);
        padding: 0;
        transition: all .3s;
        height: calc(100% - rem(13));

        .scroll-container {
            height: calc(100% - rem(56));
        }

        .empty-icon {
            filter: grayscale(100%);
            opacity: .3;
            max-width: 20%;
            min-width: rem(150);
            height: auto;
        }
    }
}

@media (min-width:1440px) {
    .activity-list-container {
        .activity-container {
            @include flex-grid-col(6, 12, 10);
        }
    }
}