@import "styles/mixins.scss";

.project-item {
    position: relative;
    transition: all 0.3s;

    .stacking-button {
        position: absolute;
        right: 0;
        margin: 16px;
        font-size: xx-large;
    }

    .issues-button {
        position: absolute;
        left: 0;
        margin: 16px;
        font-size: xx-large;
    }
}

.title {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
    margin: 0 !important;
    white-space: nowrap;
}

.subtitle {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
    white-space: nowrap;
}

.subdate {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
}

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.truncate {
    height: 24px;
}

.vertical {
    padding-top: 2px;
}

.grow {
    flex-grow: 4
}

.right {
    text-align: right;
}

@media (max-width: 900px) {
    .title {
        font-size: 1.1rem !important;
    }

    .subtitle {
        font-size: 0.775rem !important;
    }

    .subdate {
        font-size: 0.775rem !important;
    }
}

@media (min-width: 900px) and (max-width: 1200px) {
    .title {
        font-size: 1.2rem !important;
    }

    .subtitle {
        font-size: 0.825rem !important;
    }

    .subdate {
        font-size: 0.825rem !important;
    }
}

/*
    xs, extra-small: 0px
    sm, small: 600px
    md, medium: 900px
    lg, large: 1200px
    xl, extra-large: 1536px
*/
@media (min-width: 1200px) {
    .title {
        font-size: 1.3rem !important;
    }

    .subtitle {
        font-size: 0.875rem !important;
    }

    .subdate {
        font-size: 0.875rem !important;
    }
}