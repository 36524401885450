.onhover .hiddenChild {
  opacity: 0;
}

.onhover:hover .hiddenChild {
  opacity: 1;
  transition: opacity 350ms ease;
}

.box {
  transition: box-shadow 0.3s;
}

.box:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  border-radius: 8px;
}

.teamItem {
  position: relative;
}

.teamItemName {
  margin-left: 8px;
  text-overflow: ellipsis;
}

.teamItemNameText {
  width: auto;
  font-size: clamp(1rem, -0.875rem + 4vw, 1.2rem);
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

.activeTeamIndicator {
  color: rgba(0, 0, 0, 0.6);
  font-size: clamp(1rem, -0.875rem + 4vw, 1.2rem) !important;
  font-weight: 700;
  margin-left: 8px;
  text-overflow: ellipsis;
}

.teamItemActions {
  position: absolute;
  right: 0;
  margin-right: 8px;
  font-size: clamp(1.2rem, -0.875rem + 4vw, 1.5rem) !important;
  color: rgba(0, 0, 0, 0.54);
}

.form {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  vertical-align: middle;
  text-align: center;
}

.teamName {
  margin-left: 8px;
  text-overflow: ellipsis;
}