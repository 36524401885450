@import 'styles/mixins.scss';
@import 'styles/colors.module.scss';

.image-container {
    width: rem(80);
    height: rem(60);
    border-radius: rem(10);
    background-color: #EFEFEF;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: none !important;
    transform: none !important;

    svg {
        font-size: rem(30);
        color: $grey;
        line-height: 1;
    }

    &.bordered {
        border: 1px dashed $grey;
    }

    &.extra-small {
        width: rem(55);
        height: rem(41);

        svg {
            font-size: rem(20);
        }
    }

    &.small {
        width: rem(65);
        height: rem(49);

        svg {
            font-size: rem(24);
        }
    }

    &.large {
        width: rem(95);
        height: rem(71);

        svg {
            font-size: rem(36);
        }
    }
}