.announcement-root {
    pointer-events: none;

    .announcement-container {
        justify-content: end;
        align-items: start;
        width: 100vw;

        .announcement-paper {
            pointer-events: all;
            position: relative;
            height: 86px;
            padding: 0;
            max-width: 100vw;
            width: 100%;
            box-shadow: 0px 0px 7px rgba(0, 42, 76, 0.15);
            margin: 90px 90px 0 90px;

            @media (max-width: 800px) {
                height: auto;
                padding: 0;
            }

            .announcement-content {
                height: 100%;
                width: 100%;
                display: flex;
                gap: 20px;
                margin: auto 0;
                padding: 0 75px;
                align-items: center;

                @media (max-width: 800px) {
                    flex-direction: column;
                    text-align: center;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    padding-left: 0;
                    padding-right: 0;
                }

                .announcement-content-logo {
                    background-color: #003146;
                    width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;

                    .announcement-content-logo-text {
                        color: #f9af08;
                        font-size: 41px;
                    }
                }

                .announcement-content-actions {
                    display: flex;
                    margin-left: auto;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    line-height: auto;

                    svg {
                        fill: #1976d2;
                        color: #1976d2;
                    }

                    @media (max-width: 800px) {
                        margin-left: initial;
                    }
                }
            }

            .announcement-actions {
                position: absolute;
                top: 0;
                right: 0;
                margin: 0;
            }
        }
    }
}