@import "../../../styles/mixins.scss";

.title {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
    margin: 0 !important;
    /* white-space: nowrap; */
    font-size: clamp(1.1rem, -0.875rem + 4vw, 1.3rem) !important;
}

.subtitle {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
    /* white-space:nowrap; */
    font-size: clamp(0.725rem, -0.875rem + 4vw, 0.875rem) !important;
}

.container {
    display: flex;
    flex-direction: row;
    /* flex-wrap: nowrap; */
    justify-content: flex-start;
    align-items: center;
    margin-bottom: rem(10);
}

.grow {
    align-self: flex-start;
    flex-grow: 4;
    display: flex;
    gap: 0rem;
    flex-direction: column;
    margin-left: rem(10);
}

.last {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-start;
    width: rem(80);
}

.main {
    width: rem(145);
}

img.dim {
    height: rem(90);
    object-fit: cover;
    width: 100%;
}

@media (max-width:1200px) {
    .grow {
        align-self: center;
        margin-bottom: rem(5);
    }

    .last {
        align-self: center;
        margin-bottom: rem(15);
        margin-right: 0;
    }

    .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}