.container {
    width: 100%;
    height: 100%;
}

.modelviewer {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
    outline: none;
}

.component {
    height: 100%;
    width: 100%;
    position: relative;
}

.loadingOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    opacity: 0.75;
}

.loadingCircle {
    width: 96px !important;
    height: 96px !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.selectionChips {
    position: absolute;
    top: 20px;
    right: 20px;
}

.selectionChips > div {
    margin-left: 10px;
    margin-bottom: 10px;
}

.chipCounter {
    height: 32px !important;
    width: 32px !important;
    margin-left: 0 !important;
    color: rgb(194, 194, 194) !important;
    background-color: rgba(0, 0, 0, 0.26) !important;
}

.chipElement {
    opacity: 1;
    background-color: rgb(194, 194, 194) !important;
}

.chipEntity {
    opacity: 1;
    background-color: rgb(194, 194, 194) !important;
}