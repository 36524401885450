// VARIOUS
$white: #fff;
$border: rgba(0, 42, 76, 0.1);
$red: #E30000;
$red-light: #e97590;
$green: #7dbd36;
$green-light: #bede9b;
$blue: #42A5f5;
$grey: #D8D8D8;

// PRIMARY
$primary: #f6a81c;
$primary-light: #fdc010;
$primary-dark: #d47128;
$primary-contrast: $white;

// SECONDARY
$secondary: #fefefe;
$secondary-light: #d8d8d828;
$secondary-dark: #f6f6f6;

// ERROR
$error: #d32f2f;
$error-light: #e53935;
$error-dark: #c62828;
$error-lighter: #f44336;

// WARNING
$warning: #f57c00;
$warning-light: #fb8c00;
$warning-dark: #ef6c00;
$warning-lighter: #ff9800;

// ICON
$icon: #757575;
$icon-light: #9e9e9e;
$icon-dark: #616161;
$icon-lighter: #bdbdbd;

// STATES
$state-open: #81c784;
$state-onhold: #fbc02d;
$state-closed: #616161;
$state-resolved: #388e3c;
$state-active: #42a5f5;

// BACKGROUND
$background: $white;

// TEXT
$text-primary: #575757;
$text-secondary: rgba(0, 0, 0, 0.6);
$text-tertiary: rgba(0, 42, 76, 0.4);
$text-third: $white;
$text-disabled: #b4b4b4;
$text-alternate: rgba(0, 42, 76, 0.4);

$theme-colors: (
  "primary": $primary,
  "primaryLight": $primary-light,
  "primaryDark": $primary-dark,
  "primaryContrast": $primary-contrast,

  "secondary": $secondary,
  "secondaryLight": $secondary-light,
  "secondaryDark": $secondary-dark,

  "error": $error,
  "errorLight": $error-light,
  "errorLighter": $error-lighter,
  "errorDark": $error-dark,

  "warning": $warning,
  "warningLight": $warning-light,
  "warningLighter": $warning-lighter,
  "warningDark": $warning-dark,

  "icon": $icon,
  "iconLight": $icon-light,
  "iconLighter": $icon-lighter,
  "iconDark": $icon-dark,

  "stateOpen": $state-open,
  "stateOnHold": $state-onhold,
  "stateClosed": $state-closed,
  "stateResolved": $state-resolved,
  "stateActive": $state-active,

  "background": $background,

  "textPrimary": $text-primary,
  "textSecondary": $text-secondary,
  "textThird": $text-third,
  "textDisabled": $text-disabled,
);

:export {

  @each $key,
  $value in $theme-colors {
    #{$key}: #{$value};
  }
}