.text {
    display: block;
    margin: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subtitle {
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.62) !important;
    /* white-space:nowrap; */
    font-size: clamp(0.725rem, -0.875rem + 4vw, 0.875rem) !important;

}