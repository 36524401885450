.buttonGrid {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 300px));
    row-gap: 10px;
}

.inputGrid {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 10px;
}

.prefInputGrid {
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;


}

.optionHeader {
    text-align: center;
    width: 100%;
    font-weight: bold;
}

.skeleton {
    height: 65px !important;
    padding: 12px;
}

.button {
    width: 200px;
    cursor: pointer;
}

.divider {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.label {
    font-weight: 700 !important;
    font-size: 14px !important;
    color: black !important;
}

.dialogPaper {
    width: 600px !important;
    height: 750px !important;
    overflow-y: hidden;
}

.dialogContent {
    overflow-y: hidden !important;
}

.tabs {
    justify-content: space-evenly;
    width: 100%;
}

.tab {
    flex-grow: 1;
}

.dialogActions {
    display: grid !important;
    grid-template-columns: 35% 35%;
    justify-content: flex-end !important;
}

.dialogAction {
    min-width: 25% !important;
    flex-grow: 1;
}

.unitsRow {
    display: grid;
    grid-template-columns: auto 48px;
    column-gap: 10px;
    position: relative;
}

.advancedButton {
    width: 48px !important;
    height: 100%;
    margin-top: auto;
    margin-bottom: 0;
    border: 1px solid currentColor !important;
    border-radius: 4px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    margin-right: -14px !important;
}

.accordion::before {
    display: none;
}

.accordion {
    box-shadow: none !important;
}

.accordionDetailsInput {
    padding: 0 !important;
}

.prefInput {
    grid-area: input;
}

.closeButton {
    float: right;
}

.scrollBox {
    height: calc(100vh - 341px) !important;
    max-height: 630px;
    overflow-y: hidden;
}

.version-info {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 8px;
    font-size: 12px;
    color: rgba($color: #000000, $alpha: 0.4);
    font-style: italic;
}