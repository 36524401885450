.grid {
    height: 100vh;
    padding: 50px 7vw 35px 7vw;
    min-height: 100vh;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 20px;
    justify-content: 'center';
}

.content {
    flex: 1;
    margin-top: 25vh;
}

.account {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.page-heading {
    padding-bottom: 20px;
}