@import "../../../styles/mixins.scss";

.contextual-menu-container {
    padding: rem(7);
    border-radius: rem(8) !important;
    min-width: rem(190) !important;
    border: 1px solid rgba(187, 185, 189, .4) !important;
    box-shadow: 0px 4px 16px -8px rgba(0, 0, 0, 0.16) !important;

    ul {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: rem(7);
    }

    hr {
        border-color: rgba(0, 0, 0, .1);
        margin: 0 !important;
        background: none !important;
    }
}