@import 'styles/mixins.scss';

.no-selection {
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: rem(35);
    font-style: italic;
    color: rgba(0, 0, 0, 0.24);
}