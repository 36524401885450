.field {
    width: 0;
}

.field-enter {
    width: 0;
}

.field-enter-active {
    width: 200px;
    transition: width 500ms ease-in-out;
}

.field-enter-done {
    width: 200px;
    transition: width 500ms ease-in-out;
}

.field-exit {
    width: 200px;
}

.field-exit-active {
    width: 0;
    transition: width 500ms ease-in-out;
}

.field-exit-done {
    width: 0;
    transition: width 500ms ease-in-out;
}