.grid {
    height: 100vh;
    padding: 50px 7vw 35px 7vw;
    min-height: 100vh;
    width: 100%;
}

.section {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.divider {
    margin-top: 12px;
}

.account {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.small {
    font-size: 0.75rem;
    float: left;
    white-space: nowrap;
}

@media (max-width: 300px) {
    .divider {
        font-size: 0.70rem;
    }
}


@media (max-width: 600px) {
    .small {
        font-size: 0.65rem;
    }
}

@media (max-width: 900px) {
    .grid {
        padding: 50px 5vw 0 5vw;
    }
}


@media (min-width: 900px) and (max-width: 1200px) {
    .grid {
        padding: 50px 5vw 0 5vw;
    }
}