.notificationContainer {
    display: grid;
    width: 100%;
    grid-template-columns: 64px auto 24px;
    column-gap: 5px;
    grid-template-areas:
        "thumbnail subject symbol"
        "thumbnail project symbol"
        "thumbnail date null";
}

.notificationDialogList {
    padding: 0 !important;
}

.notificationDialog {
    width: 400px !important;
    border-radius: 8px !important;
}

.notificationTitle {
    opacity: 1 !important;
    background-color: #eeeeee !important;
}

.thumbnailContainer {
    width: 64px;
    height: 64px;
}

.loadingBarContainer {
    height: 0;
    width: 100%;
    text-align: center;
    background-color: white;
    position: relative;
}

.loadingBar {
    position: absolute;
    bottom: 0;
    height: 24px;
    font-size: 100%;
    width: 100%;
    border-radius: 0 !important;
}