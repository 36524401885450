@import "../styles/mixins.scss";

.tabStyles {
    position: absolute !important;
    pointer-events: none !important;
    z-index: 2 !important;
}

.rightDockedTab {
    pointer-events: all !important;
    position: inherit !important;
    min-width: 20vw !important;
    right: 100% !important;
    transform-origin: right !important;
}

.leftDockedTab {
    pointer-events: all !important;
    position: inherit !important;
    min-width: 20vw !important;
    left: 100% !important;
    transform-origin: left !important;
}

.tabHeader {
    height: 60px;
    padding: 15px;
    margin: auto;
    border: 0px !important;
    width: 100% !important;
}

.tabPaper {
    display: grid;
    grid-template-rows: 60px auto 40px;
    grid-template-areas: "header" "content" "actions";
}

.tabActions {
    grid-area: actions;
    text-align: center;
}

.panelTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.search {
    margin-left: auto;
    display: flex;
    overflow: hidden;
}

.skeletonContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .skeleton {
        width: 100%;
        padding: 0;
        margin: 0;

        &.rect {
            border-radius: 16px;
        }
    }
}

.inline-input-wrapper {
    height: auto;
    border: none !important;

    svg {
        color: #F9AF08;
        transition: color .3s;
        height: rem(28);
        width: rem(28);
    }

    &:hover {
        border: none !important;

        svg {
            color: darken(#F9AF08, 10);
        }
    }
}

.inline-input-container {
    padding-bottom: 0;
}

.add-icon {
    color: #F9AF08;
    transition: color .3s;
    height: rem(28);
    width: rem(28);
}